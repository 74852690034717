import moment from "moment";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { ReportPrinter } from "./ReportPrinter";
import { FontStyle, Styles } from "jspdf-autotable";
import { grey } from "@mui/material/colors";
import type { FakeSchedule as FakeScheduleType } from "../data/api/types";
import { getDayContent, PartialDriver } from "../pages/reports/FakeSchedule";
import { getSettingsByGarage } from "../common/useGarageSettings";

function capitalize(s: string): string {
  return s[0].toUpperCase() + s.slice(1);
}

export function usePrintFakeSchedule(
  schedule: readonly {
    readonly sl_number: number;
    readonly days: readonly string[];
  }[]
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );

  const selectedTransport = useSelector(
    (state: RootState) => state.filters.selectedTransport
  );

  const [needsAutocolumnSelect] = useSelector((state: RootState) => [
    state.filters.needsAutocolumnSelect,
  ]);

  const { depots, selectedDepot } = useDepotFilter();

  const { printFakeLeaves, printFakeSickLeaves, printFakeWork } =
    getSettingsByGarage(selectedTransport, selectedDepot);

  const exportPdf = useCallback(
    async (
      headers: readonly {
        content: string;
        isHoliday: boolean;
      }[],
      drivers: Record<number, PartialDriver>,
      fakeSchedule: FakeScheduleType["data"],
      year: number,
      month: number,
      extended: boolean
    ) => {
      setIsDone(false);
      setHasError(false);
      setIsLoading(true);

      try {
        const scheduleMonth = moment(
          `${year.toString().padStart(4, "0")}-${month
            .toString()
            .padStart(2, "0")}-01`,
          "YYYY-MM-DD"
        );

        let printer: ReportPrinter;

        const extraRows = selectedTransport !== "A" ? 9 : 0;
        const scheduleRows = schedule.length + extraRows;

        if (selectedTransport === "TB" && selectedDepot === 6) {
          // Надежда - 6, Искър - 5
          const monthLong = capitalize(
            moment(
              `${year.toString().padStart(4, "0")}-${month
                .toString()
                .padStart(2, "0")}-01`,
              "YYYY-MM-DD"
            ).format("MMMM")
          );
          const depotText =
            selectedTransport === "TB" ? "тролейбусно депо" : "трамвайно депо";
          const depotName = `"${
            depots
              .find((d) => d.id === selectedDepot)
              ?.value?.toLocaleUpperCase() || "?"
          }"${
            needsAutocolumnSelect
              ? ", Автокoлона " + selectedAutoColumn.toString()
              : ""
          }`;

          printer = new ReportPrinter({
            marginX: 25,
            marginY: 40,
            orientation: "landscape",
            headerText: `ГРАФИК на водачите от ${depotText} ${depotName} за месец ${monthLong} ${year} г.`,
          });

          if (
            !extended &&
            ((scheduleRows >= 23 && scheduleRows <= 26) ||
              (scheduleRows > 26 &&
                ((scheduleRows - 26) % 34 > 31 ||
                  (scheduleRows - 26) % 34 < 4)))
          ) {
            printer.printSpace(60);
          } else {
            printer.printSpace(5);
          }

          printer.setFont("normal", 10, 1.2);

          printer.printText(
            "Утвърдил Директор: ...............................",
            printer.printableWidth + printer.pageMarginX,
            "page",
            { align: "right" }
          );
          printer.printSpace(15);

          printer.setFont("bold", 14, 1.2);
          printer.printText(
            `ГРАФИК`,
            printer.printableWidth / 2 + printer.pageMarginX,
            "page",
            { align: "center" }
          );

          printer.setFont("bold", 9, 1.2);
          printer.printText(
            `на водачите от ${depotText} ${depotName} за месец ${monthLong} ${year} г.`,
            printer.printableWidth / 2 + printer.pageMarginX,
            "page",
            { align: "center" }
          );
          printer.printSpace(7);

          printer.setFont("normal", 9, 1.2);
          printer.printText(
            "Работната смяна и номерът на колата за всеки работен ден се конкретизира с дневния наряд за съответната дата от месеца.",
            printer.printableWidth / 2 + printer.pageMarginX,
            "page",
            { align: "center" }
          );
          printer.printText(
            "Времето за почивка се осигурява с разписанието за деня и влиза в работното време.",
            printer.printableWidth / 2 + printer.pageMarginX,
            "page",
            { align: "center" }
          );
          printer.printSpace(7);

          const today = moment().format("LL");
          printer.setFont("italic", 9, 1.2);
          printer.printText(
            today,
            printer.printableWidth / 2 + printer.pageMarginX,
            "page",
            { align: "center" }
          );
          printer.printSpace(7);
        } else {
          const sGarage_text = selectedTransport !== "A" ? "Депо" : "АП";

          printer = new ReportPrinter({
            marginX: 25,
            marginY: 40,
            orientation: "landscape",
            headerText: `ГРАФИК ЗА ПОЧИВКИ НА ВОДАЧИ ЗА М.${month} / ${year},  ${sGarage_text} "${
              depots
                .find((d) => d.id === selectedDepot)
                ?.value?.toLocaleUpperCase() || "?"
            }${
              selectedTransport === "A" || needsAutocolumnSelect
                ? " , Автокoлона " + selectedAutoColumn.toString()
                : '"'
            }`,

            // ", АВТОКОЛОНА ${selectedAutoColumn}, НОРМЕН БРОЙ ПОЧИВКИ: ${
            //   holidays.filter((h) => h).length
            // }`,
          });

          if (
            (scheduleRows >= 26 && scheduleRows <= 29) ||
            (scheduleRows > 29 && (scheduleRows - 29) % 34 > 31)
          ) {
            printer.printSpace(60);
          } else {
            printer.printSpace(5);
          }

          printer.setFont("normal", 10, 1.2);

          printer.printText(
            "Утвърдил Директор: ...............................",
            printer.printableWidth + printer.pageMarginX,
            "page",
            { align: "right" }
          );
          printer.printSpace(15);

          printer.setFont("bold", 14, 1.2);
          printer.printText(
            `ГРАФИК ЗА ПОЧИВКИ НА ВОДАЧИ ЗА М.${month} / ${year}`,
            printer.printableWidth / 2 + printer.pageMarginX,
            "page",
            { align: "center" }
          );

          const today = moment().format("LL");
          printer.setFont("italic", 9, 1.2);
          printer.printText(
            today,
            printer.printableWidth / 2 + printer.pageMarginX,
            "page",
            { align: "center" }
          );
          printer.printSpace(7);

          printer.setFont("bold", 10, 1.2);
          printer.printText(
            `${sGarage_text} "${
              depots.find((d) => d.id === selectedDepot)?.value.toUpperCase() ||
              "? "
            } `,

            // `АП " ${
            //   depots.find((d) => d.id === selectedDepot)?.value.toUpperCase() ||
            //   "?"
            // }"`,
            printer.printableWidth / 2 + printer.pageMarginX,
            "page",
            { align: "center" }
          );
          printer.printSpace(0);
        }

        const columns = [
          extended ? "№" : [],
          extended ? "Сл. №" : "№",
          "Водач",
          headers.map((h) => h.content),
          selectedTransport === "A" ? "Кола" : [],
          [],
          extended ? [] : "Поч.",
          extended ? [] : selectedTransport !== "A" ? "Подпис" : [],
        ].flat();

        const headStyles: Partial<Styles> = {
          font: "Roboto",
          fontStyle: "bold",
          fontSize: 9,
          cellPadding: { horizontal: 4, vertical: 3 },
        };

        const bodyStyles: Partial<Styles> = {
          font: "Roboto",
          fontStyle: "light" as FontStyle,
          //cellPadding: 4,
          cellPadding: { horizontal: 4, vertical: 2 },
        };

        const tableDrivers = fakeSchedule.schedule.filter((driver) =>
          Object.hasOwn(drivers, driver.sl_number)
        );

        printer.autoTable({
          // html: "#fake-schedule-table",
          styles: {
            halign: "center",
            valign: "middle",
            font: "Roboto",
            fontStyle: "normal",
            fontSize: 9,
            cellPadding: 4,
            lineColor: [0, 0, 0],
            lineWidth: 0.25,
            //lineWidth: 0,
            // minCellHeight: extended ? 20 : undefined,
          },
          headStyles: headStyles,
          bodyStyles: bodyStyles,
          columnStyles: Object.fromEntries(
            (extended
              ? [
                  [
                    0,
                    {
                      cellWidth: 25,
                      halign: "left",
                      valign: "top",
                    },
                  ],
                  [
                    1,
                    {
                      cellWidth: selectedTransport === "A" ? 30 : 35,
                      halign: "left",
                      valign: "top",
                    },
                  ],
                  [
                    2,
                    {
                      halign: "left",
                      fontSize: 8,
                      overflow: "linebreak",
                    },
                  ],
                  [
                    scheduleMonth.daysInMonth() + 3,
                    {
                      cellWidth: 30,
                      halign: "left",
                    },
                  ],
                  [
                    scheduleMonth.daysInMonth() + 4,
                    {
                      cellWidth: selectedTransport === "A" ? 30 : 40,
                      halign: "left",
                    },
                  ],
                ]
              : [
                  [
                    0,
                    {
                      cellWidth: selectedTransport === "A" ? 30 : 35,
                      halign: "left",
                      valign: "top",
                    },
                  ],
                  [
                    1,
                    {
                      halign: "left",
                      fontSize: 8,
                      overflow: "ellipsize",
                    },
                  ],
                  [
                    scheduleMonth.daysInMonth() + 2,
                    {
                      cellWidth: 30,
                      halign: "left",
                    },
                  ],
                  [
                    scheduleMonth.daysInMonth() + 3,
                    {
                      cellWidth: selectedTransport === "A" ? 30 : 40,
                      halign: "left",
                    },
                  ],
                ]
            ).concat(
              fakeSchedule.holidays.map(
                (h, hIdx) =>
                  [
                    hIdx + (extended ? 3 : 2),
                    {
                      cellWidth: extended ? 20 : undefined,
                      fillColor: h ? grey[300] : undefined,
                    },
                  ] as any
              )
            )
          ),
          showHead: !extended,
          didDrawCell: extended
            ? (data) => {
                if (
                  data.section === "body" &&
                  data.cell.colSpan == columns.length
                ) {
                  // console.log(data);
                  const nextHeader = data.table.body[data.row.index + 1];
                  const nextLine = data.table.body[data.row.index + 2];
                  const nextEmpty = data.table.body[data.row.index + 3];

                  if (nextHeader && nextLine && nextEmpty && data.cursor) {
                    const nextHeight =
                      nextHeader.height + nextLine.height + nextEmpty.height;
                    const nextEnd =
                      data.cursor.y + data.row.height + nextHeight;
                    const nextRowEnd =
                      data.cursor.y + data.row.height + nextHeader.height;
                    const tableEnd = printer.totalHeight - printer.pageMarginY;

                    if (nextEnd > tableEnd && nextRowEnd <= tableEnd) {
                      // data.cursor.y = printer.pageMarginY - data.row.height;
                      // printer.newPage();
                      data.cursor.y += nextHeight;
                    }
                  }
                }
              }
            : undefined,
          columns: columns,
          body: tableDrivers
            .map((driver, idx) => [
              extended
                ? columns.map((c) => ({
                    content: c,
                    styles: bodyStyles,
                  }))
                : [],
              [
                extended ? (idx + 1).toString() : [],
                driver.sl_number,
                drivers[driver.sl_number]?.name || "",
                driver.days.map((_day, dayIdx) =>
                  getDayContent(
                    driver,
                    dayIdx,
                    printFakeLeaves,
                    printFakeSickLeaves,
                    printFakeWork
                  )
                ),
                selectedTransport === "A"
                  ? drivers[driver.sl_number]?.attributes?.preferred_vehicle ||
                    ""
                  : [],
                extended
                  ? []
                  : driver.days.filter((d) => d[0]?.toLocaleLowerCase() === "п")
                      .length,
                extended ? [] : selectedTransport !== "A" ? " " : [],
              ]
                .flat()
                .map((content, idx) => ({
                  content,
                  styles: extended
                    ? {
                        ...headStyles,
                        fontSize: idx > 1 ? 11 : 9,
                        // minCellHeight: 57,
                      }
                    : bodyStyles,
                })),
              extended
                ? [
                    {
                      content: " ",
                      colSpan: columns.length,
                    },
                  ]
                : [],
            ])
            .concat(
              extended
                ? [...new Array(extraRows)].map((_, idx) => [
                    columns.map((c) => ({
                      content: c,
                      styles: bodyStyles,
                    })),
                    [
                      {
                        content: (tableDrivers.length + 1 + idx).toString(),
                        styles: { ...headStyles, fontSize: 9 },
                      },
                      { content: " ", styles: {} },
                      { content: " ", styles: { ...headStyles, fontSize: 11 } },
                    ],
                    [{ content: "", styles: {}, colSpan: columns.length }],
                  ])
                : [...new Array(extraRows)].map(() => [
                    [{ content: "", styles: {} }],
                  ])
            )
            .flat()
            .filter((r) => r.length > 0),
        });

        printer.printSpace(20);

        // const startY = printer.currentPageY;
        const fontSize = 10;
        const betweenLines = 12.5;
        const expectedHeight = 2 * fontSize + betweenLines - fontSize;

        if (
          printer.currentPageY + expectedHeight + fontSize >
          printer.printableHeight + printer.pageMarginY
        ) {
          printer.newPage();
          printer.printSpace(20);
        }

        const size1 = printer.getDimensions("Н-К АВТОКОЛОНА:");
        const size2 = printer.getDimensions("ЗАМ. ДИРЕКТОР:");

        if (selectedTransport === "A") {
          printer.setFont("normal", fontSize, 1);
          const size1 = printer.printText("Н-К АВТОКОЛОНА:");
          printer.printSpace(-size1.height);

          printer.doc.setLineWidth(0.25);
          printer.doc.setLineDashPattern([1, 3], 0);
          printer.doc.line(
            printer.pageMarginX + size1.width + 5,
            printer.currentPageY,
            printer.pageMarginX + size1.width + 5 + 180,
            printer.currentPageY
          );

          const size2 = printer.printText(
            "ЗАМ. ДИРЕКТОР:",
            printer.pageMarginX + size1.width + 200
          );
          printer.doc.line(
            printer.pageMarginX + size1.width + 200 + size2.width + 5,
            printer.currentPageY - size2.height,
            printer.pageMarginX + size1.width + 200 + size2.width + 5 + 180,
            printer.currentPageY - size2.height
          );

          printer.printSpace(betweenLines);
        }

        const size3 = printer.printText("НАРЯДЧИК:");
        printer.printSpace(-size3.height);
        printer.printText("1:", printer.pageMarginX + size1.w, "page", {
          align: "right",
        });
        printer.printSpace(-size3.height);
        printer.doc.setLineDashPattern([1, 3], 0);
        printer.doc.line(
          printer.pageMarginX + size1.w + 5,
          printer.currentPageY,
          printer.pageMarginX + size1.w + 5 + 180,
          printer.currentPageY
        );

        if (selectedTransport === "A") {
          printer.printText(
            "2:",
            printer.pageMarginX + size1.w + size2.w + 200,
            "page",
            { align: "right" }
          );
          printer.printSpace(-size3.height);
          printer.doc.line(
            printer.pageMarginX + size1.w + 200 + size2.w + 5,
            printer.currentPageY,
            printer.pageMarginX + size1.w + 200 + size2.w + 5 + 180,
            printer.currentPageY
          );
        } else {
          const size4 = printer.printText(
            "ПРОВЕРИЛ (НАЧАЛНИК ЕКСПЛОАТАЦИЯ):",
            printer.pageMarginX + printer.printableWidth / 2
          );
          printer.printSpace(-size4.height);
          printer.printText(
            "1:",
            printer.pageMarginX +
              size4.width +
              printer.pageMarginX +
              printer.printableWidth / 2,
            "page",
            {
              align: "right",
            }
          );
          printer.printSpace(-size4.height);
          printer.doc.setLineDashPattern([1, 3], 0);
          printer.doc.line(
            printer.pageMarginX +
              size4.width +
              5 +
              printer.pageMarginX +
              printer.printableWidth / 2,
            printer.currentPageY,
            printer.pageMarginX +
              size4.width +
              5 +
              180 +
              printer.pageMarginX +
              printer.printableWidth / 2,
            printer.currentPageY
          );
        }

        printer.save(
          `График-за-почивки-${year}-${month}-${
            depots.find((d) => d.id === selectedDepot)?.value || "-"
          }-${selectedAutoColumn}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.pdf`
        );
      } catch (err) {
        console.error("Cannot export into PDF:", err);
        setHasError(true);
      } finally {
        setIsDone(true);
        setIsLoading(false);
      }
    },
    [
      depots,
      needsAutocolumnSelect,
      printFakeLeaves,
      printFakeSickLeaves,
      printFakeWork,
      selectedAutoColumn,
      selectedDepot,
      selectedTransport,
    ]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
  };
}
